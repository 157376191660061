<template>
  <div>
    <div class="page-title">
      <h3>Карта
        <a class="btn-floating waves-effect waves-light blue darken-4" @click="openFilter">
          <i class="small material-icons">filter_list</i>
        </a>
      </h3>
      <span>
        <select id="working_shift_selector" ref="working_shift_selector"
                v-model="workingShiftSelected"
                @change="showPerformerInfo(workingShiftSelected)">
          <option value="" selected>Все исполнители</option>
          <option v-for="r in workingShifts" :key="r.id" :value="r">
            <span v-bind:class="{'red-text': !r.latest_location_time}">
              {{ r.user.last_name }} {{ r.user.first_name }}
            </span>
          </option>
        </select>
      </span>
    </div>

    <div class="row" v-show="taskFilterOpened">
      <div class="col s12 m3 l2" v-if="isAdmin">
        <div class="input-field">
          <select id="department_filter" ref="departmentSelector" v-model="departmentId"
                  @change="applyTaskFilter">
            <option value="" selected>Любой</option>
            <option v-for="r in departmentList" :key="r.id" :value="r.id">{{ r.name }}</option>
          </select>
          <label for="department_filter">Отдел</label>
        </div>
      </div>

      <div class="col s12 m3 l2">
        <div class="input-field">
          <select id="type_filter" ref="typeSelector" v-model="type" @change="applyTaskFilter" multiple>
            <option v-for="r in typeList" :key="r" :value="r">{{ r }}</option>
          </select>
          <label for="type_filter">Тип</label>
        </div>
      </div>

      <div class="col s12 m3 l2">
        <div class="input-field">
          <select id="status_selector" ref="statusSelector" v-model="statusId"
                  @change="applyTaskFilter">
            <option value="">Все</option>
            <option value="assigned" selected>Только назначенные</option>
            <option value="unassigned">Только не назначенные</option>
          </select>
          <label for="status_selector">Статус назачения</label>
        </div>
      </div>

      <div class="col s12 m3 l1">
        <div class="input-field">
          <input id="planned_end_time_from" type="text" v-model.lazy="planned_end_time_from_filter"
                 autocomplete="off"
                 class="datepicker" v-datePicker="planned_end_time_from_filter"
                 @change="applyTaskFilter">
          <label for="planned_end_time_from">План.время ОТ</label>
        </div>
      </div>

      <div class="col s12 m3 l1">
        <div class="input-field">
          <input id="planned_end_time_to" type="text" v-model.lazy="planned_end_time_to_filter"
                 autocomplete="off"
                 class="datepicker" v-datePicker="planned_end_time_to_filter" @change="applyTaskFilter">
          <label for="planned_end_time_to">План.время ДО</label>
        </div>
      </div>
    </div>

    <vl-map data-projection="EPSG:4326" style="height: 740px" ref="map">
      <vl-view :zoom.sync="zoom" :center.sync="center"></vl-view>

      <vl-layer-tile>
        <vl-source-osm></vl-source-osm>
      </vl-layer-tile>

      <vl-overlay v-if="featuresObjects" v-for="feature in featuresObjects" class="feature-popup" :key="feature.id"
                  :position="pointOnSurface(feature.geometry)" positioning="top-center">
        <template slot-scope="popup">
          <vl-feature :key="'marker_' + feature.id">
            <vl-geom-point v-bind:coordinates="feature.geometry.coordinates"></vl-geom-point>
            <vl-style-box>
              <vl-style-icon :src="feature.marker" :scale="0.6"></vl-style-icon>
            </vl-style-box>
          </vl-feature>
          <section class="feature-card">
            <header class="feature-card-header">
              {{ feature.object.name }}
            </header>
            <div class="feature-card-content">
              <div class="content">
                <a href="#" @click="showObjectTasks(feature.object)" @click.prevent>Заявок:
                  {{ feature.object.task_count }}</a>
              </div>
            </div>
          </section>
        </template>
      </vl-overlay>

      <vl-overlay v-if="featuresUsers" v-for="feature in featuresUsers" class="feature-popup" :key="feature.id"
                  :position="pointOnSurface(feature.geometry)" positioning="top-center">
        <template slot-scope="popup">
          <vl-feature :key="'marker_' + feature.id">
            <vl-geom-point v-bind:coordinates="feature.geometry.coordinates"></vl-geom-point>
            <vl-style-box>
              <vl-style-circle :radius="10">
                <vl-style-fill :color="feature.marker"></vl-style-fill>
              </vl-style-circle>
            </vl-style-box>
          </vl-feature>
          <section class="feature-card">
            <header class="feature-card-header">
              <a href="#" @click="showPerformerInfo(feature.ws)" @click.prevent>{{ feature.ws.user.last_name + ' ' + feature.ws.user.first_name }}</a>
            </header>
            <div class="feature-card-content">
              <div class="content">
                {{ feature.ws.latest_location_time.seconds * 1000 | date('datetime') }}
              </div>
            </div>
          </section>
        </template>
      </vl-overlay>

      <section v-if="tracking.length > 0">
        <vl-feature v-for="(point, id) in tracking" :key="'tracking_point_list_' + point.id">
          <vl-geom-point v-bind:coordinates="getCoordinates(point.location)"></vl-geom-point>
          <vl-style-box>
            <vl-style-circle :radius="getPointRadius(id)">
              <vl-style-fill v-bind:color="getPointColor(point, id)"></vl-style-fill>
              <vl-style-stroke color="rgb(13, 71, 161)"></vl-style-stroke>
            </vl-style-circle>
            <vl-style-text v-bind:text="'  ' + getDate(point) + '(' + point.battery_capacity + '%)'"
                           font="bold 14px monospace"
                           textAlign="end"></vl-style-text>
          </vl-style-box>
        </vl-feature>

        <vl-feature>
          <vl-geom-line-string v-bind:coordinates="trackingLineCoordinates"></vl-geom-line-string>
          <vl-style-box>
            <vl-style-stroke color="rgb(13, 71, 161)" :width="3"></vl-style-stroke>
          </vl-style-box>
        </vl-feature>
      </section>
    </vl-map>

    <div id="modalTaskList" class="modal">
      <div class="modal-content" v-if="objectSelected">
        <h4>Список заявок для объекта {{ objectSelected.name }}</h4>
        <h6>{{ objectSelected.locality }}, {{ objectSelected.address }}</h6>
        <table>
          <thead>
          <tr>
            <th class="center-align">ID</th>
            <th>Название</th>
            <th>Тип</th>
            <th>Бригада</th>
            <th>Статус</th>
            <th>План. дата завершения</th>
            <th>План. внешняя дата завершения</th>
          </tr>
          </thead>

          <tbody>
          <tr v-for="(record, idx) of taskList" :key="record.id">
            <td class="center-align">
              <a v-bind:href="('/tasks/' + record.id)" target="_blank" v-tooltip="'Открыть'"
                 class="btn-small btn blue darken-4">
                {{ record.id }}
              </a>
              <div v-if="record.external_id">
                <div class="chip no-margin" v-tooltip="'Внешний ID'">{{ record.external_id }}</div>
              </div>
            </td>
            <td>
              {{ record.title }}
              <div v-if="record.description !== ''" class="task_list_description">
                {{ record.description }}
              </div>
            </td>
            <td>
              <section v-if="record.type">{{ record.type }}</section>
              <section v-else>Нет</section>
            </td>
            <td>
          <span v-if="record.team">
            {{ record.team.name }}
          </span>
              <span v-else>Нет</span>
            </td>
            <td>
          <span v-if="record.object" class="valign-wrapper">
            {{ record.status | status() }}
          </span>
            </td>
            <td>
              <section v-if="record.planned_end_time">
                {{ record.planned_end_time.seconds * 1000 | date('date') }}
              </section>
              <section v-else>Нет</section>
            </td>
            <td>
              <section v-if="record.external_planned_end_time">
                {{ record.external_planned_end_time.seconds * 1000 | date('datetime') }}
              </section>
              <section v-else>Нет</section>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <a href="#!" class="modal-close waves-effect blue darken-4 btn-flat white-text">Закрыть</a>
      </div>
    </div>

    <div id="modalTaskListPerformer" class="modal">
      <div class="modal-content" v-if="workingShiftSelected">
        <h4>Список заявок для бригады {{ workingShiftSelected.team.name }}</h4>
        <table>
          <thead>
          <tr>
            <th class="center-align">ID</th>
            <th>Название</th>
            <th>Тип</th>
            <th>Объект</th>
            <th>Статус</th>
            <th>План. дата завершения</th>
            <th>План. внешняя дата завершения</th>
          </tr>
          </thead>

          <tbody>
          <tr v-for="(record, idx) of taskList" :key="record.id">
            <td class="center-align">
              <a v-bind:href="('/tasks/' + record.id)" target="_blank" v-tooltip="'Открыть'"
                 class="btn-small btn blue darken-4">
                {{ record.id }}
              </a>
              <div v-if="record.external_id">
                <div class="chip no-margin" v-tooltip="'Внешний ID'">{{ record.external_id }}</div>
              </div>
            </td>
            <td>
              {{ record.title }}
              <div v-if="record.description !== ''" class="task_list_description">
                {{ record.description }}
              </div>
            </td>
            <td>
              <section v-if="record.type">{{ record.type }}</section>
              <section v-else>Нет</section>
            </td>
            <td>
            {{ record.object.address }}
            </td>
            <td>
              {{ record.status | status() }}
            </td>
            <td>
              <section v-if="record.planned_end_time">
                {{ record.planned_end_time.seconds * 1000 | date('date') }}
              </section>
              <section v-else>Нет</section>
            </td>
            <td>
              <section v-if="record.external_planned_end_time">
                {{ record.external_planned_end_time.seconds * 1000 | date('datetime') }}
              </section>
              <section v-else>Нет</section>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <a href="#!" class="modal-close waves-effect blue darken-4 btn-flat white-text">Закрыть</a>
      </div>
    </div>

    <div id="performerInfoModal" class="mapDownModal" v-if="workingShiftSelected">
      <div class="mapDownModalClose">
        <a @click="closePerformerInfoModal">
          <i class="tiny material-icons">close</i>
        </a>
      </div>
      <div class="mapDownModalContent">
        <div class="row">
          <h6>{{ workingShiftSelected.user.last_name }} {{ workingShiftSelected.user.first_name }} {{ workingShiftSelected.user.middle_name }}</h6>
        </div>
        <div class="row">
          <strong>Бригада:</strong> {{ workingShiftSelected.team.name }}
        </div>
        <div class="row">
          <strong>Инженер:</strong> {{ workingShiftSelected.engineer.last_name }} {{ workingShiftSelected.engineer.first_name }}
        </div>
        <div class="row">
          <strong>Водитель:</strong>&nbsp;
          <span v-if="workingShiftSelected.driver">Да</span>
          <span v-else>Нет</span>
        </div>
        <div class="row">
          <strong>Заряд батареи:</strong> {{ workingShiftSelected.battery_capacity }}%
        </div>
        <div class="row">
          <strong>Время начала смены</strong><br/>
          {{ workingShiftSelected.created_at.seconds * 1000 | date('datetime') }}
        </div>
        <div class="row">
          <strong>Время последней активности</strong><br/>
          <span v-if="workingShiftSelected.latest_location_time">
            {{ workingShiftSelected.latest_location_time.seconds * 1000 | date('datetime') }}
          </span>
          <span v-else>Нет данных</span>
        </div>
        <div class="row" v-if="workingShiftSelected.released_at">
          <strong>Время освобождения бригады</strong><br/>
          {{ workingShiftSelected.released_at.seconds * 1000 | date('datetime') }}
        </div>
        <div class="row" v-if="workingShiftSelected.estimated_end_time">
          <strong>Ожидаемое время прибытия домой</strong><br/>
          {{ workingShiftSelected.estimated_end_time.seconds * 1000 | date('datetime') }}
        </div>
        <div class="row" v-if="workingShiftSelected.latest_location_time">
          <strong>Интервал трекинга</strong><br/>
          <span v-for="interval in trackingIntervalList" class="trackingIntervalSelect">
            <span v-if="interval === trackingInterval">{{ interval }}</span>
            <span v-else>
              <a href="#" @click="setTrackingInterval(interval)" @click.prevent>{{ interval }}</a>
            </span>
          </span>
        </div>
        <div class="row">
          <a href="#" @click="showPerformerTasks" @click.prevent>Список заявок</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {findPointOnSurface} from 'vuelayers/lib/ol-ext'
import dateFilter from "../filters/date.filter";

export default {
  name: 'maps',
  metaInfo() {
    return {
      title: this.$title('Карта')
    }
  },
  data: () => ({
    zoom: 9,
    center: [39.603098, 55.050564],
    isAdmin: false,
    departmentList: [],
    departmentSelector: null,
    departmentId: '',
    typeList: [],
    typeSelector: null,
    type: ['ТЗ', 'КТО', 'ППР'],
    statusSelector: null,
    statusId: 'assigned',
    workingShifts: [],
    workingShiftSelected: '',
    workingShiftSelector: null,
    objectSelected: null,
    planned_end_time_from_filter: '',
    planned_end_time_to_filter: '',
    taskList: [],
    objectList: [],
    memberList: [],
    featuresUsers: [],
    featuresObjects: [],
    tracking: [],
    trackingInterval: 10,
    trackingIntervalList: [10, 5, 3, 1],
    modalTaskList: null,
    modalTaskListPerformer: null,
    taskFilterOpened: false
  }),
  async mounted() {
    this.isAdmin = await this.$store.dispatch('profile/isAdmin')

    if (this.isAdmin) {
      const departments = await this.$store.dispatch('fetchDepartments')
      this.departmentList = departments.filter(department => department.id > 1)
    } else {
      this.departmentId = await this.$store.dispatch('profile/getDepartmentId');
    }

    this.typeList = await this.$store.dispatch('fetchTypes')
    this.workingShifts = await this.$store.dispatch('fetchActiveWorkingShifts', this.departmentId)

    await this.applyTaskFilter()
    await this.applyWorkingShift()

    setTimeout(() => {
      this.modalTaskList = M.Modal.init(document.querySelector('#modalTaskList'));
      this.modalTaskListPerformer = M.Modal.init(document.querySelector('#modalTaskListPerformer'));
      this.workingShiftSelector = M.FormSelect.init(this.$refs.working_shift_selector)
      this.departmentSelector = M.FormSelect.init(this.$refs.departmentSelector)
      this.typeSelector = M.FormSelect.init(this.$refs.typeSelector)
      this.statusSelector = M.FormSelect.init(this.$refs.statusSelector)
    })
  },
  methods: {
    getCoordinates(location) {
      return [location.lng, location.lat]
    },
    getObjectName(obj) {
      if (!obj) {
        return ""
      }

      if (obj.external_id) {
        return obj.external_id + ' (GSI ' + obj.id + ')'
      } else {
        return obj.id
      }
    },
    async showObjectTasks(object) {
      this.taskList = []
      this.objectSelected = object

      let status = []

      if (this.statusId === 'assigned') {
        status = ['not_started', 'in_progress']
      } else if (this.statusId === 'unassigned') {
        status = ['waiting']
      } else {
        status = ['waiting', 'not_started', 'in_progress']
      }

      const payload = {
        department_id: this.departmentId,
        type: this.type,
        status: status,
        planned_end_time_from: this.planned_end_time_from_filter,
        planned_end_time_to: this.planned_end_time_to_filter,
        object: "GSI" + object.id.toString(),
        sort_field: 't.id',
        sort_direction: 'desc',
        size: 100,
      }

      if (!this.isAdmin) {
        payload['engineer_id'] = await this.$store.dispatch('profile/getUid')
      }

      let response = await this.$store.dispatch('fetchTasks', payload)

      if (response.tasks != null) {
        this.taskList = response.tasks
      }

      this.modalTaskList.open();
    },
    async applyTaskFilter() {
      this.objectList = []
      let status = []

      if (this.statusId === 'assigned') {
        status = ['not_started', 'in_progress']
      } else if (this.statusId === 'unassigned') {
        status = ['waiting']
      } else {
        status = ['waiting', 'not_started', 'in_progress']
      }

      const payload = {
        department_id: this.departmentId,
        type: this.type,
        status: status,
        planned_end_time_from: this.planned_end_time_from_filter,
        planned_end_time_to: this.planned_end_time_to_filter,
        sort_field: 't.id',
        sort_direction: 'desc',
        size: 5000,
      }

      if (!this.isAdmin) {
        payload['engineer_id'] = await this.$store.dispatch('profile/getUid')
      }

      let response = await this.$store.dispatch('fetchTasks', payload)

      if (response.tasks != null) {
        let objectList = []

        response.tasks.forEach(task => {
          if (
            (this.statusId === 'assigned' && ['not_started', 'in_progress'].includes(task.status) === false) ||
            (this.statusId === 'unassigned' && ['waiting'].includes(task.status) === false)
          ) {
            return
          }

          if (!objectList.hasOwnProperty(task.object.id)) {
            objectList[task.object.id] = {
              'id': task.object.id,
              'name': this.getObjectName(task.object),
              'locality': task.object.locality,
              'address': task.object.address,
              'location': task.object.location,
              'task_count': 0,
              'exists_assigned_tasks': false,
              'exists_unassigned_tasks': false,
            }
          }

          objectList[task.object.id].task_count = objectList[task.object.id].task_count + 1

          if (task.status === 'waiting') {
            objectList[task.object.id].exists_unassigned_tasks = true
          } else if (task.status === 'not_started' || task.status === 'in_progress') {
            objectList[task.object.id].exists_assigned_tasks = true
          }
        })

        this.objectList = []

        objectList.forEach(obj => {
          this.objectList.push(obj)
        })
      }

      this.updateObjectFeatures()
    },
    applyWorkingShift() {
      this.memberList = []

      if (this.workingShifts != null) {
        this.workingShifts.forEach(ws => {
          if (this.workingShiftSelected && this.workingShiftSelected.user.id !== ws.user.id) {
            return
          }

          this.memberList.push(ws)
        })
      }

      this.updateUserFeatures()
    },
    updateUserFeatures() {
      let featuresUsers = []
      this.featuresUsers = []

      this.memberList.forEach(ws => {
        let marker = 'rgb(13, 71, 161)'

        if (ws.latest_location_time === null || ws.latest_location === null) {
          return
        }

        if (ws.battery_capacity < 30 || Math.floor(Date.now() / 1000) - ws.latest_location_time.seconds > 1800) {
          marker = 'rgb(183, 28, 28)'
        }

        featuresUsers.push({
          type: 'Feature',
          id: 'ws_' + ws.id,
          geometry: {
            type: 'Point',
            coordinates: this.getCoordinates(ws.latest_location),
          },
          ws: ws,
          marker: marker
        })
      })

      setTimeout(() => {
        this.featuresUsers = featuresUsers
      }, 100)

      if (this.workingShiftSelected) {
        this.center = [this.workingShiftSelected.latest_location.lng, this.workingShiftSelected.latest_location.lat]
        this.zoom = 15
      }
    },
    updateObjectFeatures() {
      let featuresObjects = []
      this.featuresObjects = []

      Object.values(this.objectList).forEach(object => {
        let marker = ''

        if (object.exists_unassigned_tasks && object.exists_assigned_tasks) {
          marker = '/img/icons/location_yellow.png'
        } else if (!object.exists_unassigned_tasks && object.exists_assigned_tasks) {
          marker = '/img/icons/location_green.png'
        } else {
          marker = '/img/icons/location_red.png'
        }

        featuresObjects.push({
          type: 'Feature',
          id: 'object_' + object.id,
          location: object.location,
          geometry: {
            type: 'Point',
            coordinates: this.getCoordinates(object.location),
          },
          object: object,
          marker: marker,
        })
      })

      setTimeout(() => {
        this.featuresObjects = featuresObjects
      }, 100)
    },
    openFilter() {
      this.taskFilterOpened = !this.taskFilterOpened
    },
    async showPerformerInfo(ws) {
      if (ws === '') {
        return this.closePerformerInfoModal()
      }

      this.workingShiftSelected = ws
      this.applyWorkingShift()

      await this.loadTracking()
    },
    closePerformerInfoModal() {
      this.workingShiftSelected = ''
      this.tracking = []
      this.applyWorkingShift()
    },
    async showPerformerTasks() {
      this.taskList = []

      const payload = {
        team_id: this.workingShiftSelected.team.id,
        status: ['not_started', 'in_progress'],
        sort_field: 't.id',
        sort_direction: 'desc',
      }

      let response = await this.$store.dispatch('fetchTasks', payload)

      if (response.tasks != null) {
        this.taskList = response.tasks
      }

      this.modalTaskListPerformer.open();
    },
    async loadTracking() {
      let tracking = await this.$store.dispatch('fetchWorkingShiftTracking', {
        wsId: this.workingShiftSelected.id,
        skipInterval: this.trackingInterval
      })

      if (tracking.length > 0 && tracking[tracking.length-1].created_at.seconds !== this.workingShiftSelected.latest_location_time.seconds) {
        let latestTrackingItem = tracking[tracking.length-1]

        if (latestTrackingItem.created_at.seconds < this.workingShiftSelected.latest_location_time.seconds) {
          tracking.push({
            id: 'latest_location',
            created_at: this.workingShiftSelected.latest_location_time,
            location: this.workingShiftSelected.latest_location,
            battery_capacity: this.workingShiftSelected.battery_capacity,
          })
        } else {
          this.workingShiftSelected.latest_location_time = latestTrackingItem.created_at
          this.workingShiftSelected.latest_location = latestTrackingItem.location
          this.workingShiftSelected.battery_capacity = latestTrackingItem.battery_capacity
        }
      }

      this.tracking = tracking
    },
    getPointColor(point, id) {
      let baseRed = 13
      let baseGreen = 71
      let baseBlue = 161

      if (point.accuracy >= 200 || point.rejected === true) {
        baseRed = 255
        baseGreen = 255
        baseBlue = 0
      } else {
        let targetRed = 255
        let targetGreen = 255
        let targetBlue = 255

        let distanceRed = targetRed - baseRed
        let distanceGreen = targetGreen - baseGreen
        let distanceBlue = targetBlue - baseBlue

        let step = id / this.tracking.length
        baseRed += distanceRed * step
        baseGreen += distanceGreen * step
        baseBlue += distanceBlue * step
      }

      return `rgb(${baseRed}, ${baseGreen}, ${baseBlue})`
    },
    getPointRadius(idx) {
      if (idx === 0 || idx === this.tracking.length - 1) {
        return 10
      }

      return 5
    },
    getDate(point) {
      return dateFilter(point.created_at.seconds * 1000, 'time')
    },
    async setTrackingInterval(interval) {
      this.trackingInterval = interval
      await this.loadTracking()
    },
    pointOnSurface: findPointOnSurface,
  },
  computed: {
    trackingLineCoordinates: function () {
      let coords = []

      this.tracking.forEach(point => {
        coords.push([point.location.lng, point.location.lat])
      })

      return coords
    },
  },
  components: {},
  destroyed() {
    if (this.workingShiftSelector && this.workingShiftSelector.destroy) {
      this.workingShiftSelector.destroy()
    }
    if (this.departmentSelector && this.departmentSelector.destroy) {
      this.departmentSelector.destroy()
    }
    if (this.typeSelector && this.typeSelector.destroy) {
      this.typeSelector.destroy()
    }
    if (this.statusSelector && this.statusSelector.destroy) {
      this.statusSelector.destroy()
    }
    if (this.modalTaskList && this.modalTaskList.destroy) {
      this.modalTaskList.destroy()
    }
    if (this.modalTaskListPerformer && this.modalTaskListPerformer.destroy) {
      this.modalTaskListPerformer.destroy()
    }
  },
}
</script>

<style scoped>
.feature-popup {
  margin-top: 20px;
  text-align: center;
}

.feature-card {
  font-size: 0.8rem;
  text-shadow: 0 0 2px white;
  font-weight: bold;
}
.mapDownModal {
  position: absolute;
  bottom: 50px;
  margin-left: 20px;
  width: 350px;
  height: auto;
  background-color: white;
  border: 1px solid #0D47A1;
  overflow: auto;
  font-size: 13px;
}
.mapDownModalClose {
  position: absolute;
  right: 5px;
  top: 5px;
}
.mapDownModalContent {
  margin: 15px;
}
.mapDownModalContent .row {
  margin-bottom: 10px;
}
span.trackingIntervalSelect {
  margin-right: 10px;
}
</style>
